import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Container, Col, Row } from "react-bootstrap";
import InnerBanner from "../../components/InnerBanner";
import styles from "./index.module.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Forms from "../../components/Form";
import Inputs from "../../components/Inputs";
import Dropdown from "../../components/Dropdown";
import * as yup from "yup";
import States from "../states/states";
import Loader from "../../components/LoadingIndicator";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import inputComponentStyles from "../../components/Inputs/index.module.css";

export const ContactUs = (location) => {
  const [selectedState, setSelectedState] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [mailerResponse, setMailResponse] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [state, setState] = useState([
    { code: "", name: "Please Select a State" },
  ]);

  const handleClose1 = () => setShowModal(false);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
    window.scrollTo(0, 0);
    axios
      .post(
        "https://vo4bm2sbhi.execute-api.us-east-1.amazonaws.com/prod/public/state/list",
        { companyCode: "SUNWST000", domain: "swmc.com" },
        {
          headers: { "Content-Type": "application/json" },
        }
      )
      .then(
        (response) => {
          const stateArr = [...state, ...response.data];
          setState(stateArr);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  const changeState = (evt) => {
    setSelectedState(evt.target.value);
  };

  const closeSweetAlert = () => {
    setShowModal(false);
  };

  useEffect(() => {
    console.log(mailerResponse);
    if (mailerResponse != " ") {
      setIsLoading(false);
    }
    if (mailerResponse != "") {
      setShowModal(true);
      setTimeout(() => {
        // setShowModal(false);
      }, 5000);
      setTimeout(() => {
        setMailResponse("");
      }, 2500);
    }
  }, [mailerResponse]);

  const SignupSchema = yup.object().shape({
    // State: yup
    //         .string()
    //         .test('val', 'Not Applicable in selected state', val => val && val.toString() !== 'NEW YORK')
    //         .test('val',"Please select a state",val =>  val && val.toString() !== 'Please Select a State'),
    email: yup
      .string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+(?:\.[a-z-]+)+$/,
        "Not a valid mail format"
      ),

    // email: yup
    //   .string()
    //   // .required('Email is required')
    //   .matches(
    //     /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+(?:\.[a-z-]+)+$/,
    //     'Please enter a valid Email',
    //   ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(SignupSchema),
  });

  const onSubmit = (data, e) => {
    if (!data.type) {
      reset("");
      const formData = new FormData();
      formData.append("postData", JSON.stringify(data));
      setIsLoading(true);

      axios.post(process.env.REACT_APP_CONTACT_US, formData).then(
        (response) => {
          setMailResponse(response.data.success);
          console.log(response);
        },
        (error) => {
          setMailResponse(error);
          console.log(error);
        }
      );
    }
    if (data.type) {
      data.target.reset();
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Contact Us</title>
        <meta property="og:url" content="https://bankers.plus/contact-us" />
        <meta property="og:title" content="Banker's Plus - Contact Us" />
        <meta
          property="og:description"
          content="Residential mortgage loan and financing are provided by Banker's Plus"
        />
        <meta
          property="og:keyword"
          content="Banker's Plus contact, Corporate Headquarters, Brain Turner, Sun West Mortgage Company, Orangethorpe Avenue, Suite 500, Buena Park, California 90620, inquiry@swmc.com"
        />
        <meta property="og:brand" content="Banker's Plus" />
      </Helmet>

      <Container className={styles.formContainer}>
        <Row>
          <Col>
            <div className={styles.headline_title}>Contact Us</div>
            <p className={styles.headline_subtitle}>
              "Banker's Plus" Contact Form
              <br />
              <span>
                Fill out this form and we'll get back to you as soon as
                possible!
              </span>
            </p>
          </Col>
        </Row>
        <Row className={styles.formAreaRow}>
          {isLoading === false ? (
            <>
              <Col md={8} className={styles.formCol}>
                <div className={styles.formarea}>
                  <Forms
                    formClass={styles.formClass}
                    style={{}}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                  >
                    <Row>
                      <Col sm={6}>
                        <Inputs
                          register={register("FirstName")}
                          errors={errors}
                          type="text"
                          name="FirstName"
                          placeholder="First Name"
                        />
                      </Col>
                      <Col sm={6}>
                        <Inputs
                          register={register("LastName")}
                          errors={errors}
                          type="text"
                          name="LastName"
                          placeholder="Last Name"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Inputs
                          register={register("Telephone")}
                          errors={errors}
                          type="text"
                          name="Telephone"
                          placeholder="Contact No"
                          check={null}
                        />
                      </Col>
                      <Col md={6}>
                        <Inputs
                          register={register("email")}
                          errors={errors}
                          type="email"
                          name="email"
                          placeholder="Email*"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Inputs
                          register={register("FinancialInstitution")}
                          errors={errors}
                          type="text"
                          name="FinancialInstitution"
                          placeholder="Financial Institution"
                        />
                      </Col>
                      <Col sm={6}>
                        <Inputs
                          register={register("Address")}
                          errors={errors}
                          type="text"
                          name="Address"
                          placeholder="Address"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Inputs
                          register={register("City")}
                          errors={errors}
                          type="text"
                          name="City"
                          placeholder="City"
                        />
                      </Col>
                      <Col sm={6}>
                        <Dropdown
                          className={inputComponentStyles.inputStyle}
                          register={register("State")}
                          errors={errors}
                          values={state}
                          name="State"
                          placeholder="State"
                          Change={(evt) => changeState(evt)}
                        />
                      </Col>
                    </Row>
                    <Inputs
                      register={register("Comment")}
                      errors={errors}
                      type="textarea"
                      name="Comment"
                      placeholder="Comment/Questions"
                      style={{
                        marginBottom: "0",
                      }}
                    />
                    <Row>
                      <Col>
                        <div className={styles.consentDesc}>
                          <p className="m-0">
                            <strong>Communication Consent</strong>
                          </p>
                          <p className="m-0">
                            By submitting your contact information, you agree to
                            our{" "}
                            <a
                              href="https://www.swmc.com/terms-and-conditions.php"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Terms of Use
                            </a>{" "}
                            and our{" "}
                            <a
                              href="https://www.swmc.com/privacy-policy.php"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Security and Privacy Policy.{" "}
                            </a>
                            You also expressly consent to having Sun West
                            Mortgage Company contact you about your inquiry by
                            text message or phone (including automatic telephone
                            dialling system or an artificial or pre-recorded
                            voice) to the residential or cellular telephone
                            number you have provided, even if that telephone
                            number is on a corporate, state, or national Do Not
                            Call Registry. You do not have to agree to receive
                            such calls or messages as a condition of getting any
                            services from Sun West Mortgage Company or its
                            affiliates. By communicating with us by phone, you
                            consent to calls being recorded and monitored.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Forms>
                </div>
              </Col>
              <Col md={4} className={styles.infoCol + " p-0"}>
                <Container
                  fluid
                  className="m-0 p-0"
                  style={{
                    whiteSpace: "break-spaces",
                  }}
                >
                  <Row className={styles.rowOne}>
                    <p>
                      <b>Anthony Toro</b>
                      <br></br>
                      Managing Director / Branch Manager<br></br>
                      Email:&nbsp;
                      <a
                        href="mailto:anthony@swmc.com"
                        title="anthony@swmc.com"
                      >
                        anthony@swmc.com
                      </a>
                      <br></br>
                      D:&nbsp;
                      <a title="Call Anytime!" href="tel:+16266769609">
                        (626) 676-9609
                      </a>
                      <br></br>
                    </p>
                  </Row>
                  <br></br>
                  <Row className={styles.rowTwo}>
                    <h3
                      style={{
                        marginTop: "8px",
                      }}
                    >
                      Corporate Headquarters
                    </h3>
                    <p className="m-0 p-0">
                      <strong>Sun West Mortgage Company, Inc.</strong>
                      <br></br>
                      <strong>Branch NMLS ID:</strong>&nbsp;3277<br></br>
                      6131 Orangethorpe Avenue, Suite 500<br></br>Buena Park,
                      California 90620<br></br>
                      <strong>Phone:</strong>&nbsp;
                      <a href="tel:+15629247884">(562) 924-7884</a>
                      <br></br>
                      <strong>Toll Free:</strong>&nbsp;
                      <a href="tel:+18004537884">(800) 453-7884</a>
                      <br></br>
                      <strong>Fax:</strong>&nbsp;
                      <a href="tel:+15629246057">(562) 924-6057</a>
                      <br></br>
                      <strong>Email:</strong>&nbsp;
                      <a
                        href="mailto:inquiry@swmc.com"
                        class="link_gray_m_investment_property_loans"
                      >
                        inquiry@swmc.com
                      </a>
                      <br></br>
                    </p>
                  </Row>
                </Container>
              </Col>
            </>
          ) : (
            <div
              style={{
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
              }}
            >
              <Loader />
            </div>
          )}
        </Row>
      </Container>
      {mailerResponse !== " " && mailerResponse != "error" ? (
        <SweetAlert
          show={showModal}
          success
          title="Success!"
          onConfirm={closeSweetAlert}
          timeout={4000}
        >
          <div>
            <Row>
              <Col className="text-center">
                <p>
                  Thank you for contacting Sun West. We will get back to you
                  soon. You may also directly contact:
                </p>
                <p>
                  <span style={{ fontWeight: "bold" }}>Anthony Toro</span>
                  <br></br>
                  <span>Managing Director / Branch Manager</span>
                  <br></br>
                  <span style={{ display: "inline-flex" }}>
                    <span style={{ fontWeight: "bold" }}>Email:</span>&nbsp;
                    <a href="mailto:anthony@swmc.com" title="anthony@swmc.com">
                      anythony@swmc.com
                    </a>
                    <br></br>
                  </span>
                  <br></br>
                  <span style={{ display: "inline-flex" }}>
                    <span style={{ fontWeight: "bold" }}>D:</span>&nbsp;
                    <a title="Call Any Time" href="tel:+16266769609">
                      (626) 676-9609
                    </a>
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </SweetAlert>
      ) : (
        <SweetAlert
          show={showModal}
          error
          title="Failed!"
          onConfirm={null}
          timeout={2000}
        >
          Failed to send mail,please try again in a while
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

export default ContactUs;
