import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactGA from 'react-ga';

import Homepage from './containers/Homepage'
import AboutUsPage from './containers/AboutUs'
import LoanProgramsPage from './containers/LoanPrograms'
import SolutionsPage from './containers/SunWestMortgageSolutions'
import PortalPage from './containers/ServicingPortal'
import ContactPage from './containers/ContactUs'
import AddOnCards from './components/HomepageComponents/AddOnCardsSection';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import TexasDisclosurePage from './containers/TexasDisclosure/Loadable';
import DisclaimerPage from './containers/Disclaimer'
import './App.css'
import ErrorFallback from './containers/ErrorFallback'
import NotFoundPage from './containers/NotFoundPage'


ReactGA.initialize('G-ZS4CXPYX1D')

function App() {

  // const {history, location} = useHistory()

  return (

    <ErrorFallback>
    <div className="App">
      <Helmet
        // titleTemplate="%s - swmc.com"
        defaultTitle="Banker's Plus"

      />
      
      <BrowserRouter>
        <NavBar />
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/about-us" component={AboutUsPage} />
          <Route exact path="/loan-programs" component={LoanProgramsPage} />
          <Route exact path="/sun-west-mortgage-solutions" component={SolutionsPage} />
          <Route  path="/contact-us" component={ContactPage} />
          <Route exact path="/portal" component={PortalPage} />
          <Route exact path="/TXdis" component={TexasDisclosurePage} />
          <Route exact path="/disclaimer" component={DisclaimerPage} />
          <Route path="" component={NotFoundPage} />
        </Switch>
        <AddOnCards />
        <Footer />
      </BrowserRouter>
      

    </div>
    </ErrorFallback>
  );
}

export default App;
