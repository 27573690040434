import { Container } from "react-bootstrap";
import styles from "./index.module.css";
import styled from "styled-components";
import checkmark from "../../../../../assets/images/checkmark.svg";
import ModalHeader from "../ModalHeader";
const data = [
  "Conventional",
  "FHA",
  "VA",
  "USDA-RD",
  "Renovation",
  "Energy Mortgage",
  "HUD-184 Native American",
  "Reverse Mortgages (HECM)",
  "Jumbo",
  "Bank Statement Loans",
  "Moderate Credit Loans",
  "Self-employed",
  "Multi Family",
  "Non-QM Alternate Qualifying Loans",
];

const { container } = styles;
const ModalWrapper = styled.div`
  & ul {
    list-style: none;
    list-style-position: outside;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  & li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  & .pseudo-li {
    background-image: url(${checkmark});
    background-size: contain;
    display: flex;
    min-width: 10px;
    width: 10px;
    height: 12px;
    margin-top: 6px;
    margin-right: 5px;
    background-repeat: no-repeat;
  }
`;
const PartnerModalContent = ({ closeModal }) => {
  return (
    <ModalWrapper>
      <Container fluid className={container}>
        <div onClick={closeModal} className={styles.close}>
          x
        </div>
        <div className={styles.padbox}>
          <ModalHeader title="Partner with Sun West!" />
          <div style={{ padding: "1rem 1rem 1rem 2rem" }}>
            Sun West Mortgage Company, Inc. is a FHA, VA, Freddie Mac, Fannie
            Mae** and Ginnie Mae approved full-service mortgage banker. Sun West
            has been servicing its nationwide client base since 1980. Sun West's
            diverse loan programs include both QM & Non-QM offerings:
            <br></br>
            **Fannie Mae® is a registered trademark of Fannie Mae.
          </div>
          <div style={{ padding: "2rem 2rem 1rem 2rem" }}>
            <ul>
              {data.map((item) => (
                <li>
                  <div className="pseudo-li"></div>
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Container>
    </ModalWrapper>
  );
};

export default PartnerModalContent;
