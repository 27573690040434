import { Card, Col } from "react-bootstrap";
import parse from "html-react-parser";
// import { lazy } from '@loadable/component';

const CustomCard = ({
  cardStyles,
  contentStyles,
  // cardBodyStyles,
  genericClassnameObj,
  content,
  onClickCard,
}) => {
  const { img, heading, description, actionableItem } = content;
  // const Img = lazy(() => import(img));

  const { card, cardImage, cardBody, title, subTitle, actionable } =
    genericClassnameObj;

  return (
    <div
      className="custom-card-container"
      style={cardStyles}
      onClick={onClickCard ? onClickCard : null}
    >
      <Card className={`${card}`}>
        <Col
          style={{
            padding: "0",
          }}
        >
          <span className={`${cardImage}`}>
            <Card.Img
              variant={
                contentStyles && contentStyles.imgPosition
                  ? contentStyles.imgPosition
                  : "top"
              }
              src={img}
            />
          </span>
        </Col>
        <Card.Body
          className={`${cardBody}`}
          style={{
            padding: "0",
          }}
        >
          <Card.Title className={`${title}`}>
            {/* {parse(heading)} */}
            {heading}
          </Card.Title>
          {description && (
            <Card.Subtitle className={`${subTitle}`}>
              {parse(description)}
            </Card.Subtitle>
          )}
          {actionableItem() && (
            <div
              className={`${actionable}`}
              style={{
                textAlign: contentStyles.textAlign,
                ...cardStyles.footerAction,
              }}
            >
              {actionableItem()}
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default CustomCard;
