import React, { useEffect, useRef } from "react";
import styles from "./index.module.css";
import { Container, Col, Row } from "react-bootstrap";
import CustomCard from "../../components/CustomCard";
import InnerBanner from "../../components/InnerBanner";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

let navigateTo = () => {};

const data = {
  title: "Loan Programs",
  subTitle: '"Our Goal is to help Banks Prosper in Mortgage Lending"',
  cards: [
    {
      img: "../../assets/images/LoanSection/Conventional.svg",
      heading: "Conventional",
      link: "/images/LoanSection/Conventional.svg",
    },
    {
      img: "../../assets/images/LoanSection/Fannie.svg",
      heading: "Fannie Mae** HomeStyle® Renovation",
      link: "/images/LoanSection/Fannie.svg",
    },
    {
      img: "../../assets/images/LoanSection/Federal.svg",
      heading: "Federal Housing Administration (FHA)",
      link: "/images/LoanSection/Federal.svg",
    },
    {
      img: "../../assets/images/LoanSection/FHA.svg",
      heading: "FHA-203K Rehab Fixed Rate",
      link: "/images/LoanSection/FHA.svg",
    },
    {
      img: "../../assets/images/LoanSection/VA.svg",
      heading: (
        <p>
          VA-Fixed & ARMs
          <span style={{ cursor: "pointer" }} onClick={() => navigateTo("¹")}>
            ¹
          </span>
        </p>
      ), // 'VA-Fixed & ARMs¹',
      link: "/images/LoanSection/VA.svg",
    },
    {
      img: "../../assets/images/LoanSection/FHA_VA.svg",
      heading: "FHA & VA Jumbos",
      link: "/images/LoanSection/FHA_VA.svg",
    },
    {
      img: "../../assets/images/LoanSection/Conv_Jumbo.svg",
      heading: "Conventional Conforming Jumbo",
      link: "/images/LoanSection/Conv_Jumbo.svg",
    },
    {
      img: "../../assets/images/LoanSection/JumbosMil.svg",
      heading: "Jumbos up to $3 Million",
      link: "/images/LoanSection/JumbosMil.svg",
    },
    {
      img: "../../assets/images/LoanSection/USDA.svg",
      heading: "USDA Rural Development",
      link: "/images/LoanSection/USDA.svg",
    },
    {
      img: "../../assets/images/LoanSection/HECM.svg",
      heading: "HECM-Reverse Mortgages",
      link: "/images/LoanSection/HECM.svg",
    },
    {
      img: "../../assets/images/LoanSection/HUD.svg",
      heading: "HUD-184 Native American",
      link: "/images/LoanSection/HUD.svg",
    },
    {
      img: "../../assets/images/LoanSection/MultiFamily.svg",
      heading: "Multi-Family",
      link: "/images/LoanSection/MultiFamily.svg",
    },
    {
      img: "../../assets/images/LoanSection/Minium.svg",
      heading: "Condominium - Warrantable, Non-Warrantable",
      link: "/images/LoanSection/Minium.svg",
    },
    {
      img: "../../assets/images/LoanSection/Manuf_housing.svg",
      heading: "Manufactured Housing",
      link: "/images/LoanSection/Manuf_housing.svg",
    },

    {
      img: "../../assets/images/LoanSection/NonQM.svg",
      heading: "Non-QM Loans up to $3.5 Million",
      link: "/images/LoanSection/NonQM.svg",
    },

    {
      img: "../../assets/images/LoanSection/SelfEmp.svg",
      heading: "Self-Employed",
      link: "/images/LoanSection/SelfEmp.svg",
    },
    {
      img: "../../assets/images/LoanSection/SelfEmp.svg",
      heading: "HomeStyle® Energy Mortgage",
      link: "/images/LoanSection/SelfEmp.svg",
    },
  ],
};

const {
  loan_section_container,
  title,
  sub_title,
  loan_cards_container,
  loan_card,
  loan_card_image,
  loan_card_body,
  loan_card_title,
  loan_card_sub_title,
  loan_card_actionable,
  contact_us_line,
  trademark_line,
  trademark_line_style_2,
  page_title,
} = styles;

const LoanPrograms = () => {
  const one_ref = useRef(null);

  const temp = (charPassed) => {
    if (charPassed === "¹" && one_ref) {
      one_ref.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  navigateTo = temp;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <article>
      <Helmet>
        <title>Loan Programs</title>
        <meta property="og:url" content="https://bankers.plus/loan-programs" />
        <meta property="og:title" content="Banker's Plus - Loan Programs" />
        <meta
          property="og:description"
          content="Residential mortgage loan and financing are provided by Banker's Plus"
        />
        <meta
          property="og:keyword"
          content="Conventional, Home Style Renovation, FHA, FHA-203K Rehab Fixed & ARMs, VA-Fixed & ARMs, FHA & VA Jumbos, Conventional, Conforming Jumbo, Jumbos up to $2.5 Million, USDA-RD, HECM-Reverse Mortgages, HUD-184 Native American, Multi-Family, Condominium - Warrantable, Non-Warrantable, Condotels, Manufactured Housing, Rural Living - Primary Home and all Land Fixed, Rate - 5 to 160 acres, Non-QM Loans, Investor, Self-Employed"
        />
        <meta property="og:brand" content="Banker's Plus" />
      </Helmet>
      <React.Fragment>
        <Container className={loan_section_container}>
        <div className={page_title}>Loan Products</div>
          {/* <div className={title}>{data.title}</div>
            <div className={sub_title}>{data.subTitle}</div> */}
          <Row className={loan_cards_container}>
            {data.cards.map((card) => (
              <Col
                className={styles.loan_cards_columns}
                lg={2}
                md={3}
                sm={4}
                xs={5}
              >
                <CustomCard
                  genericClassnameObj={{
                    card: loan_card,
                    cardImage: loan_card_image,
                    cardBody: loan_card_body,
                    title: loan_card_title,
                    subTitle: loan_card_sub_title,
                    actionable: loan_card_actionable,
                  }}
                  cardStyles={{
                    padding: "0",
                  }}
                  contentStyles={{
                    textAlign: "center",
                  }}
                  content={{
                    img: card.img,
                    heading: card.heading,
                    description: card.description,
                    actionableItem: () => false,
                  }}
                />
              </Col>
            ))}
          </Row>
          {/* <Row>
                        <Col lg={6} md={6} sm={12} className={contact_us_line}>
                            <div>
                                <Link to='/contact-us'>Contact Us</Link> for further details on our Loan Programs.
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={12} className={trademark_line}>
                            **Fannie Mae® is a registered trademark of Fannie Mae.
                        </Col>
                    </Row> */}
          <Row className={trademark_line_style_2}>
            <Col className="text-center">
              <p>**Fannie Mae® is a registered trademark of Fannie Mae.</p>
              <p ref={one_ref}>¹This is an Adjustable Rate Mortgage.</p>
              <p>HomeStyle is a registered trademark of Fannie Mae.</p>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <Col>
              <p
                style={{
                
                  paddingTop: "1rem",
                }}
              >
                To know more on Loan Programs:
              </p>
              <p>
                <Link
                  to="/contact-us"
                  style={{
                    boxShadow: "0 0px 3px #083F88",
                  }}
                  className={styles.knowMoreLink}
                >
                  Contact Us
                </Link>
              </p>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </article>
  );
};

export default LoanPrograms;
