/*
 * Licences Page
 *
 */

import React, { useEffect, memo, Suspense, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import Table from '../../components/table';
import Classes from './index.module.css';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import Loader from '../../components/LoadingIndicator';
import axios from 'axios';
import logoImg from "../../assets/images/swmc-logo.png"
import InnerBanner from '../../components/InnerBanner'


const LicenseWrapper = styled.div`
font-size:15px;

 .table-bordered{
     border:none;
     margin-bottom:0px
 }
`;


function Licenses({
	
	fetchUnderLicensesData
}) {
	

	const [licenses,setLicenses]= useState("")

	useEffect(() => {
		window.scrollTo(0, 0)
		//fetching api and storing data if it is empty

		
		axios.post('https://vnan0el5rf.execute-api.us-west-1.amazonaws.com/prod/Swmc_State_Disclaimer')
        .then((response) => {
          setLicenses(response.data)
          console.log(response);
        }, (error) => {
        //   setMailResponse(error)
          console.log(error);
        });
		
		



	}, [])

		
	


	let licenseTableHeaders;
	let licenseTableBody;

	let licenseContent;

	function htmlDecode(input) {
		var e = document.createElement('textarea');
		e.innerHTML = input;
		// handle case of empty input
		return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
	}

	function convertHTMLEntity(text) {
		const span = document.createElement('span');
		return text
			.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
				span.innerHTML = entity;
				return span.innerText;
			});
	}





	if (licenses != "" && licenses !== undefined && licenses.length > 0) {

		licenseContent = licenses.map(ele => {
			let removeChars = ele.DISCLAIMER.replace("â€‹", "''")
			const disc = htmlDecode(removeChars)
			const convertedDescription = convertHTMLEntity(disc)
			return (
				<Col md={12}>
					<div className={Classes.license_border}></div>
					<h2>{ele.STATE}</h2>
					<div className={Classes.license_content}>
						<p>
							{convertedDescription}
						</p>
					</div>

				</Col>

			)

		})

	}
	



	return (
		<article>
			<Helmet>
				<title>Sun West Mortgage Company - Disclaimer.</title>
				<meta property="og:url" content="https://sunwestmortgage.com/disclaimer" />
				<meta property="og:title" content="SWMC - Disclaimers" />
				<meta property="og:description" content="Purchase, refinancing, property rehabilitation loan, and reverse mortgage are among the residential house loan mortgages offered by Sun West Mortgage Comapny, Inc." />
				<meta property="og:keyword" content="swmc disclaimer" />
				<meta property="og:brand" content="Sun West Mortgage Company, Inc." />
			</Helmet>

			<InnerBanner
			title="Licenses"/>
			<LicenseWrapper>
				<div>

					
					<Container className={Classes.pageContainer}>
						<Row>
							<Col md={12}>
								{/* <div className={Classes.header_border}></div> */}
								<h3>Sun West Mortgage Company, Inc. NMLS ID 3277</h3>
							</Col>
						</Row>
						<Row>
							<Col md={3} className="mt-4">
								
										<img src={logoImg} style={{width:'100%'}} />
								

							</Col>
							<Col md={6} className="mt-5">
								<div className={Classes.header_border}></div>
								<h2>State Licenses </h2>
								<p>Licenses can be verified at : <a href="https://www.nmlsconsumeraccess.org" target="_blank" rel="noopener">www.nmlsconsumeraccess.org</a></p>
								<p>In all jurisdictions, the main licensed location of Sun West Mortgage Company, Inc. is 6131 Orangethorpe Avenue, Suite 500 in Buena Park, CA 90620, Phone: (800) 453-7884.</p>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col>
								<p>
									Our products and services have no affiliation with or endorsement from any government agency or body. The housing and residential financing offered herein is open to all without regard to race, color, religion, gender, sexual orientation, handicap, familial status or national origin. Being High Risk Loans, Non Conforming Jumbo loans may include higher interest rates, closing costs. Consumers with a Reverse Mortgage can lose their home if they fail to comply with the loan terms, which include, among other terms, paying property taxes, homeowners insurance and HOA dues (if applicable) in time, maintaining the property, etc.
								</p>
							</Col>

						</Row>

						<Row className="mt-4">

							{licenseContent ?
								licenseContent
								: <Loader />}
						</Row>
					</Container>
				</div>
			</LicenseWrapper>
		</article>
	)

}

// const mapStateToProps = createStructuredSelector({
// 	licenses: makeSelectLicenses()
// });

// export function mapDispatchToProps(dispatch) {
// 	return {
// 		fetchUnderLicensesData: () => { dispatch(get_licenses_data()) },
// 	};
// }


export default Licenses;