import React, { useState, useEffect } from "react";
import { Nav, Button, Container } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import { NavLink, withRouter } from "react-router-dom";
import Classes from "./index.module.css";
import styled from "styled-components";
import logo from "../../assets/images/SunWest42logo.png";
const NavWrapper = styled.div`
  width: 100%;
  .navbar-dark .navbar-nav .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0.75rem;
    height: 2rem;
    font-size: 16px;
    color: white;
    z-index: 1200;
    margin: 0;
    line-height: normal;
  }
  .navbar-dark .navbar-nav span {
    color: white;
    display: block;
    border-right: 1px solid white;
    width: 0;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    margin: 0px 0px 0 -30px !important;
  }

  .nav-link.active {
    color: #00aae4 !important;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
    border-left: 4px solid #083f88;
    border-right: 4px solid #083f88;
    transition: all 0.1s linear;
    border-radius: 2px;
  }

  #dropdown-item-button::after {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABmJLR0QA/wD/AP+gvaeTAAAA8ElEQVQ4jWNgGAUowHLOVSHjmc+4cMkbz3zGZTnnqhCyGBOMYTD/vsDvP2w7mf5/26E99SoPNs2M/79s+vWHbZ/xzJsiGAaw/PpbycDAYPKf4b8tFzPbRmSXGM98xsX0/+tmBgZGZ0YGBn1GBuZSuD4Y4z/DhxoGBj5VRgbGwP8MDE5Ql3hxsAj+Y/z/ZdN/BkYniErGrR9+/q+D6WNEdeYZVob/fCsZGRgDoZL7IIYzOEHo/+sZGD+Fn003+Y3VAGyGIADj1g+//gffyVP9iSKKbgB2Q7BrxmkAqiFMbLg0EwTGM8+wGs88w0qyxqEFAFtSY2S3+RTSAAAAAElFTkSuQmCC");
    vertical-align: middle;
    border: none;
    margin-top: 0px;
  }
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    border-color: white;
  }
  .btn-primary:focus {
    border-color: white;
  }
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background: #fff;
    color: #0481b5;
    border: 1px solid #fff;
    box-shadow: none;
  }
  .btn-primary:not(:disabled):not(.disabled).active:focus,
  .btn-primary:not(:disabled):not(.disabled):active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    background: #fff;
    color: #0481b5;
    border: 1px solid #fff;
    box-shadow: none;
  }

  .btn-primary:hover {
    // color: #fff;
    border-color: #fff !important;
  }

  .btn-primary {
    font-size: 15px;
  }

  .channelsNav {
    .dropdown-submenu-container a.dropdown-submenu::after {
      position: absolute;
      right: 0;
      top: 0;
    }
    .dropdown-menu {
      .dropdown-toggle::after {
        content: ">";
        vertical-align: middle;
        border: none;
        margin-top: 4px;
        font-weight: 600;
        color: #dddd;
        transform: rotate(0) !important;
      }
      padding-right: 15px !important;
    }
  }
  @media (max-width: 767px) {
    .nav-link.active {
      width: 100%;
      border-width: 1px !important;
    }
    .navbar-dark .navbar-nav .nav-link {
      color: white;
      z-index: 1200;
      padding-bottom: 0.1rem !important;
      margin-bottom: 0.5rem;
      margin-left: 0.5rem;
    }
  }
  @media (max-width: 1200px) {
    .navbar-nav * {
      font-size: 14px !important;
    }
  }
`;

const NavbarComponent = (props) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navbarStyle, setNavbarStyle] = useState("");
  const [logoImage, setLogoImage] = useState("");
  const [fortyYears, setFortyYears] = useState("");
  const [applyButton, setApplyButton] = useState("");

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      // setLogoImage('/images/SunWest40YearsofTrust-White.png')
      setApplyButton(Classes.homepageButtonApply);
      setNavbarStyle(Classes.normalNav);
    } else {
      // setLogoImage('/images/SunWest40YearsofTrust-White.png')
      setApplyButton(Classes.buttonApply);
      setNavbarStyle(Classes.homepageNav);
    }
    setLogoImage(logo);
  }, []);

  useEffect(() => {
    if (
      window.location.pathname == "/" &&
      props.match.isExact == true &&
      scrollPosition < window.innerHeight + 0.2 - window.innerHeight
    ) {
      if (window.innerWidth > 768) {
        // setLogoImage('/images/SunWest41YearsofTrust-White.png')
        // setApplyButton(Classes.homepageButtonApply)
        setNavbarStyle(Classes.homepageNav);
      } else {
        // setLogoImage('/images/SunWest41YearsofTrust-White.png')
        // setApplyButton(Classes.buttonApply)
        setNavbarStyle(Classes.normalNav);
      }
    } else if (window.location.pathname != "/") {
      setNavbarStyle(Classes.normalNav);
      // setLogoImage('/images/SunWest41YearsofTrust-White.png')
      // setApplyButton(Classes.buttonApply)
    } else {
      setNavbarStyle(Classes.normalNav);
      // setLogoImage('/images/SunWest41YearsofTrust-White.png')
      // setApplyButton(Classes.buttonApply)
    }
  }, [window.location.pathname, scrollPosition]);

  useEffect(() => {
    if (
      window.location.pathname === "/" &&
      props.match.isExact == true &&
      window.pageYOffset < window.innerHeight + 0.2 - window.innerHeight
    ) {
      setScrollPosition(0);
      if (window.innerWidth > 768) {
        setNavbarStyle(Classes.homepageNav);
      } else {
        setNavbarStyle(Classes.normalNav);
      }
    }

    if (window.location.pathname === "/" && props.match.isExact == true) {
      window.addEventListener("scroll", handleScroll, { passive: true });
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [props]);

  return (
    <NavWrapper>
      <Container>
        <Navbar
          collapseOnSelect
          expand="lg"
          fixed="top"
          variant="dark"
          className={`${navbarStyle} navbar-expand-xl`}
        >
          <Navbar.Brand>
            {/* <NavLink to={{ pathname: "/" }} target="_blank" exact > */}
            <a href="https://swmc.com" target="_blank" rel="noopener">
              <img
                src={process.env.REACT_APP_CDN_LINK + "swmc-images/sunwest-trust-white-logo.png"}
                className={Classes.main_logo}
                height="auto"
                alt="swmc-logo"
              />
            </a>

            {/* </NavLink> */}
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto" style={{ margin: "auto" }}>
              {/* Leave empty for items to be on right hand side */}
            </Nav>
            <Nav>
              <Nav.Link href as={NavLink} to="/" title="Home" exact>
                Home
              </Nav.Link>{" "}
              <span></span>
              <Nav.Link href as={NavLink} to="/about-us" title="About">
                About
              </Nav.Link>
              <span></span>
              <Nav.Link
                href
                as={NavLink}
                to="/loan-programs"
                title="Loan Programs"
              >
                Loan Programs
              </Nav.Link>
              <span></span>
              <Nav.Link
                href
                as={NavLink}
                to="/sun-west-mortgage-solutions"
                title="Sun West Mortgage Solutions"
              >
                Sun West Mortgage Solutions
              </Nav.Link>
              <span></span>
              <Nav.Link href as={NavLink} to="/contact-us" title="Contact Us">
                Contact Us
              </Nav.Link>
              <span></span>
              <Nav.Link
                href
                as={NavLink}
                to="/portal"
                title="Service Portal"
                style={{ borderBottom: "1px solid transparent" }}
              >
                <Button
                  // className={applyButton}
                  className={Classes.buttonApply}
                  variant="primary"
                  size="md"
                >
                  Service Portal
                </Button>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </NavWrapper>
  );
};
export default withRouter(NavbarComponent);
