import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Classes from './index.module.css';
import { Helmet } from 'react-helmet';

export default function NotFound() {
    return (
        <article>
            <Helmet>
                <title>Banker's Plus</title>
                <meta
                    name="description"
                    content="Not found"
                />
                <meta property="og:url" content="https://sunwestmortgage.com/notfound" />
                <meta property="og:description" content="Purchase, refinancing, property rehabilitation loan, and reverse mortgage are among the residential house loan mortgages offered by Sun West Mortgage Comapny, Inc." />
                <meta property="og:keyword" content="swmc-press-releases" />
                <meta property="og:brand" content="Sun West Mortgage Company, Inc." />
            </Helmet>
            <Container fluid className={Classes.pageContainer}>
                <Row className="mt-4">
                    <Col md={12}>
                        <section className={Classes.section_terms}>
                            <h1>Oh no!</h1>
                            <p>

                                Sorry, you have reached the page which has either been moved or
                                is under update.
                            </p>

                            <p>If you are seeing this error, please write us at inquiry@swmc.com and let us know the page you were trying to access when the error occurred. We apologize for the inconvenience and thank you for your interest in Sun West Mortgage Company, Inc.</p>

                            <p>Please click <Link to='/'>https://bankers.plus</Link> to go back to the homepage.
                                </p>
                        </section>
                    </Col>
                </Row>

                
            </Container >
        </article>
    );
}
