import React, { useState, useEffect } from "react";
import { Card, Container, Col, Row, Button } from "react-bootstrap";
import CustomCard from "../../components/CustomCard";
import InnerBanner from "../../components/InnerBanner";
import styles from "./index.module.css";
import { Helmet } from "react-helmet";
import Modal from "../../components/HomepageComponents/Modal";
import Dropdown from "../../components/Dropdown";
import States from "../states/states";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const data = {
  title: "Our Service Portals",
  subTitle:
    "We run multiple services and much more content to show, lets focus on content",
  cards: [
    {
      /**
       * Now old images are commented
       */
      // img: '/images/servicesSection/LoanMgmt.svg',
      img: "../../assets/images/servicesSection/SunSoft_new_logo.png",
      heading: "Sunsoft",
      description: "Upload Application<br>Upload Documentations",
      link: "https://revmort1.reversesoftonline.com/ReverseMortgage/",
    },
    {
      // img: '/images/servicesSection/LoanStats.svg',
      img: "../../assets/images/servicesSection/seemyloanstatus.png",
      heading: "SeeMyLoanStatus",
      description:
        "Borrower Access<br>Originator Access<br> Realtor Access<br>Appraiser Access<br>Closing Agent Access",
      link: "https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf",
    },
    {
      // img: '/images/servicesSection/RateSheet.svg',
      img: "../../assets/images/servicesSection/daily_ratesheet.png",
      heading: "Rate sheet",
      description: "Daily Sun West Rate Sheet<br>Pricing Changes",
      link: "https://www.swmc.com/swmc/wholesale_lender/broker/guidelines_and_rate_sheets.php",
    },
    {
      // img: '/images/servicesSection/Commentary.svg',
      img: "../../assets/images/servicesSection/mc_top.png",
      heading: "Today's Market Commentary",
      description: "Daily Market Commentary",
      link: "https://www.swmc.com/swmc/swmc_market_commentary.php",
    },
  ],
};

const {
  services_section_container,
  title,
  sub_title,
  services_cards_container,
  title_band_wrapper,
  services_card,
  services_card_image,
  services_card_body,
  services_card_title,
  services_card_sub_title,
  services_card_actionable,
  card_padding,
  card_btn_actionable,
  custom_card_image,
} = styles;

const dropDownSchema = yup.object().shape({
  State: yup
    .object()
    .required()
    .test(
      "value",
      "Please select a state",
      (val) => val && val.toString() !== "Please select a state"
    )
    .typeError(""),
});

export const ServicesSection = () => {
  const [show, setShow] = useState(false);
  const [selectedState, setSelectedState] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: yupResolver(dropDownSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const openModal = (id) => {
    setShow(true);
  };

  const changeState = (evt) => {
    setSelectedState(evt.target.value);
  };

  return (
    <article>
      <Helmet>
        <title>Service Portal</title>
        <meta property="og:title" content="Service Portal" />
        <meta property="og:url" content="https://bankers.plus/portal" />
        <meta
          property="og:description"
          content="Residential mortgage loan and financing are provided by Banker's Plus"
        />
        <meta
          property="og:keyword"
          content="sunsoft loan management system, seemyloanstatus, my-daily rate sheet, today's market commentary"
        />
        <meta property="og:brand" content="Banker's Plus" />
      </Helmet>

      <Container fluid={true} className={services_section_container}>
        <h1 className={styles.headline_title}>Our Service Portal</h1>
        <Row className={services_cards_container}>
          {data.cards.map((card) => (
            <Col className={card_padding} xs={12} sm={6} md={6} lg={3}>
              {card.heading.toLowerCase().includes("loanstatus") ? (
                <Button
                  className={card_btn_actionable}
                  onClick={() => openModal()}
                  style={{
                    textDecoration: "none",
                    width: "100%",
                  }}
                >
                  <CustomCard
                    genericClassnameObj={{
                      card: services_card,
                      cardImage: custom_card_image,
                      cardBody: services_card_body,
                      title: services_card_title,
                      subTitle: services_card_sub_title,
                      actionable: services_card_actionable,
                    }}
                    cardStyles={{
                      padding: "0",
                      flex: "1 1",
                    }}
                    contentStyles={{
                      textAlign: "left",
                    }}
                    content={{
                      img: card.img,
                      heading: card.heading,
                      description: card.description,
                      actionableItem: () => (
                        <Button
                          className={card_btn_actionable}
                          onClick={() => openModal()}
                        >
                          Learn more
                        </Button>
                      ),
                    }}
                  />
                </Button>
              ) : (
                <a
                  href={card.link}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <CustomCard
                    genericClassnameObj={{
                      card: services_card,
                      cardImage: services_card_image,
                      cardBody: services_card_body,
                      title: services_card_title,
                      subTitle: services_card_sub_title,
                      actionable: services_card_actionable,
                    }}
                    cardStyles={{
                      padding: "0",
                      flex: "1 1",
                    }}
                    contentStyles={{
                      textAlign: "left",
                    }}
                    content={{
                      img: card.img,
                      heading: card.heading,
                      description: card.description,
                      actionableItem: () => (
                        <a
                          href={card.link}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textDecoration: "none",
                            textAlign: "left",
                            font: "normal normal 600 14px/25px normal",
                            letterSpacing: "0.56px",
                            opacity: "1",
                          }}
                        >
                          Learn more
                        </a>
                      ),
                    }}
                  />
                </a>
              )}
            </Col>
          ))}
        </Row>
      </Container>
      {show && (
        <div className={styles.emailModal}>
          <div
            onClick={() => setShow(false)}
            style={{ zIndex: 10001 }}
            className={styles.emailModal}
          ></div>
          <Container fluid className={styles.modalContent}>
            <h1 className={styles.headline_title_modal}>
              Sun West Mortgage Company Inc. - SeeMyLoanStatus&#8482;
            </h1>
            <p className={styles.headline_subtitle}>
              Please select which state the property of the loan is in:
            </p>

            <div className="d-flex flex-column flex-sm-row w-100 px-4 py-0 m-0">
              <Dropdown
                className={styles.drop}
                register={register("State")}
                errors={errors}
                values={States.States}
                name="State"
                placeholder="State*"
                Change={(evt) => changeState(evt)}
              />

              <Button
                className={styles.but}
                disabled={!selectedState}
                onClick={() => {
                  window.open(
                    "https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf",
                    "_blank"
                  );
                }}
              >
                Go
              </Button>
            </div>
          </Container>
        </div>
      )}
    </article>
  );
};

export default ServicesSection;
