import React from "react";
import styled from "styled-components";
import styles from "./Banner.module.css";
// import { useSelector } from 'react-redux';
// import bannerImage from '../../assets/images/main-banner.webp';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import welcomeright from "../../../assets/images/welcome-right-image.svg";
const BannerWrapper = styled.div`
  width: 100%;
  background-color: #083f88;
`;
const Banner = (props) => {
  const data = {
    title: "SWMC BANKER'S PLUS",
    subtitle: "Our Goal is to help Banks Prosper in Mortgage Lending",
    link_button_text: "Check out the Benefits",
    link_url: "#",
  };
  return (
    <BannerWrapper>
      <Container fluid={true} className={styles.banner_container}>
        <Row>
          <Col xl={6} lg={12} className={`${styles.desktopColumn}`}>
            <div className={styles.banner_content}>
              <div className={styles.banner_title}>
                <b>SUN WEST BANKER'S PLUS</b>
              </div>
              <div className={styles.banner_subtitle}>{data.subtitle}</div>
              <div className={styles.banner_link}>
                <Button className={styles.banner_btn} onClick={props.scroll}>
                  {data.link_button_text}
                </Button>
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-center justify-lg-end">
            <Image fluid className={styles.desktopHide} src={welcomeright} />
          </Col>
          {/* <Col lg={6} sm={12} className='banner_right_image'>
                 
                </Col> */}

          {/* <div style={{
          top: '16.5rem',
                    left: ' -12.4619140625px',
                    width: '989px',
                    height: '118px',
                    background: '#083F88 0% 0% no-repeat padding-box',
                    position: 'relative',
                    zIndex: '999'
                  }}>

                </div> */}
        </Row>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
