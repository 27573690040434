import { Container } from "react-bootstrap";
import styled from "styled-components";
import checkmark from "../../../../../assets/images/checkmark.svg";
import styles from "./index.module.css";
import { Link } from "react-router-dom";
import ModalHeader from "../ModalHeader";

const { container } = styles;
const LiWrapper = styled.div`
  & ul {
    list-style: none;
    list-style-position: outside;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  & li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  & .pseudo-li {
    background-image: url(${checkmark});
    background-size: contain;
    display: flex;
    min-width: 10px;
    width: 10px;
    height: 12px;
    margin-top: 6px;
    margin-right: 5px;
    background-repeat: no-repeat;
  }
`;
const HowToModalContent = ({ closeModal }) => {
  return (
    <Container fluid className={container}>
      <div onClick={closeModal} className={styles.close}>
        x
      </div>
      <div className={styles.padbox}>
        <ModalHeader title={`Sun West's "Banker's Plus" Partner`} />
        <p>
          To become a Sun West's "Banker's Plus" partner is as easy as 1, 2, 3.
        </p>
        <p style={{ paddingLeft: "1rem" }}>
          <ol>
            <li>
              <Link to="/contact-us" onClick={closeModal}>
                Contact Us
              </Link>{" "}
              for further details.
            </li>
            <li>Initiate the required agreement.</li>
            <li>The Banker's Plus program starts immediately.</li>
          </ol>
        </p>
        <p>
          Banker's Plus is the easiest way for a Bank to stay active and
          involved in mortgage lending. Any bank of any size can utilize this
          program to be able to offer a wide range of mortgage loan programs.
        </p>
        <p>
          How can a bank call itself a Community Bank and not offer mortgages to
          their depository base and their community? You can with Sun West’s
          "Banker's Plus" program!
        </p>
        <ModalHeader title="How It Works" />
        <p>
          The Bank identifies the perspective borrower. The Bank takes the
          application and performs certain RESPA required tasks throughout the
          loan process. The application is sent to Sun West and we do all the
          rest: Early disclosures, the Loan Estimate (LE), Processing,
          Underwriting, Closing Docs, the Closing Disclosure (CD), Close and
          Fund in the name of Sun West Mortgage Company. At Closing the Bank
          earns valuable fee income.
        </p>
        <LiWrapper>
          <ModalHeader title={`Bank Benefits by using Banker's Plus`} />
          <p>
            <ul>
              <li>
                <div className="pseudo-li"></div>
                Stay Active in Residential Mortgage Lending in your community.
              </li>
              <li>
                <div className="pseudo-li"></div>
                Offer a Full Range of Loan Products: Conventional, FHA, VA,
                USDA, Jumbo, HUD-184, Multifamily, Reverse Mortgages,
                Self-Employed Borrower, Investor, Condo Loans (Warrantable &
                Non-Warrantable), Non-QM Loans & More.
              </li>
              <li>
                <div className="pseudo-li"></div>Reduce Risk & Regulatory
                Compliance Stress.
              </li>
              <li>
                <div className="pseudo-li"></div>No Additional Overhead.
              </li>
              <li>
                <div className="pseudo-li"></div>Earn Fee Income.
              </li>
              <li>
                <div className="pseudo-li"></div>
                We Service all Fannie**, Freddie & Ginnie loans closed in the
                name of Sun West Mortgage Company - No Cross Selling!
              </li>
              <li>
                <div className="pseudo-li"></div>No Loan Loss Reserve
              </li>
              <li>
                <div className="pseudo-li"></div>No HMDA
              </li>
              <li>
                <div className="pseudo-li"></div>
                We can have you back in the mortgage business very quickly.
              </li>
            </ul>
          </p>
          <p>**Fannie Mae® is a registered trademark of Fannie Mae.</p>
          <p>
            <Link to="/contact-us" onClick={closeModal}>
              Contact Us
            </Link>{" "}
            to find out how fast and easy it is to utilize the{" "}
            <b>Banker’s Plus</b> program.
          </p>
        </LiWrapper>
      </div>
    </Container>
  );
};

export default HowToModalContent;
