import React from "react";
import styled from "styled-components";

const StyledModalHeader = styled.div`
  background: #083f88;
  border-radius: 0px 0px 100px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #ffffff !important;
  max-width: 30rem;
  width: 100%;
  line-height: 30px;
  padding: 0.75rem 0.5rem 0.75rem 2rem;
  margin: 1rem 0;
  @media (max-width: 600px) {
    max-width: calc(100% - 2rem);
  }
  @media (max-width: 400px) {
    padding: 0.75rem 0.75rem;
    font-size: 18px;
    line-height: 20px;
  }
`;
const ModalHeader = ({ title }) => {
  return <StyledModalHeader>{title}</StyledModalHeader>;
};
export default ModalHeader;
