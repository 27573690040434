import React, { useState, useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import './index.css'

const Subscriber = (props) => {

    const [emailId, setEmailId] = useState('')
    const [mailError, setMailError] = useState(false)
    const [mailErrorMessage, setMailErrorMessage] = useState('')
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {

        if (mailError === true) {
            setTimeout(() => {
                setShowModal(false);
            }, 2000);
        }
    }, [mailError]);

    const closeSweetAlert = () => {
        setShowModal(false);
    };

    const inputMail = (e) => {
        if(mailError) setMailError(false)
        setEmailId(e.target.value)
    }
    const submit = () => {
        const frm = document.getElementsByName('previewForm')[0];

        let regexEmail="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"

        // if(!regexEmail.test(emailId)){
        //     return 'Invalid Email';
        
        //   }


        // if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailId)){
        //         alert('correct')
        // }

        if ( (emailId !== '') && (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailId)) ){
            frm.submit();
            setEmailId('')
        } else {
            setShowModal(true)
            setMailError(true)

            // custom email validation message
            if(emailId === '') {
                setMailErrorMessage('Email is required')
            } else {
                setMailErrorMessage('Please enter a valid mail id')
            }
        }
    }

    return (
        <div>
            <form id="previewForm" name="previewForm" enctype="multipart/form.data" target="_blank" method="post" action="https://secure.campaigner.com/csb/Public/ProcessHostedForm.aspx" >
                <input id="__VIEWSTATEENCRYPTED" type="hidden" name="__VIEWSTATEENCRYPTED" />
                <input id="FormInfo" value="2f1521b9-a386-4e84-8cf9-3d453cb8c351" type="hidden" name="FormInfo" />

                <span className="input-group" style={{ position: 'relative' }}>
                    <input type="email" className="form-control subscribe-input" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="Enter your email address" name="1940782" contactattributeid="1940782" onChange={(e) => inputMail(e)} value={emailId} required  />
                    <button className="btn btn-primary subscribe-button" type="button" href="#" onClick={submit} title="Subscribe to our newsletter." >Subscribe</button>
                    {/* <div className="input-group-append">
                        <button className="btn btn-primary subscribe-button" type="button" href="#" onClick={submit} title="Suscribe to our newsletter.">OK</button>
                    </div> */}
                </span>
            </form>
            {
                mailError &&
                <p style={{ color: 'red', fontSize: '1rem', paddingLeft: '1rem'}}>{mailErrorMessage}</p>
            }
            {/* {
                mailError === true ? (
                    <SweetAlert
                        show={showModal}
                        error
                        title="Failed!"
                        onConfirm={closeSweetAlert}
                        timeout={4000}
                    >
                        <p style={{ color: 'red' }}>Please enter a valid mail id</p>
                    </SweetAlert>
                ) : null
            } */}
        </div >

    )
}

export default Subscriber