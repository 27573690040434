import React from "react";
import styled from "styled-components";
import Form from "react-bootstrap/Form";

const FormWrapper = styled.div`
  form {
    padding-top: 1.5rem;
    max-width: 500px;
  }

  h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
  }

  .form {
    background: #0e101c;
    max-width: 400px;
    margin: 0 auto;
  }

  p {
    color: #bf1650;
    width: 100%;
    text-align: left;
    margin-left: 5px;
    font-size: 12px;
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="email"] {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 10px;
  }
  input[type="checkbox"],
  input[type="radio"] {
    line-height: 2;
    text-align: left;
    display: flex;
    margin-bottom: 5px;
    margin-top: 5px;
    color: black;
    font-size: 14px;
    font-weight: 200;
  }

  label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
    font-weight: 200;
  }

  button[type="submit"],
  input[type="submit"] {
    display: flex;
    justify-content: center;
    margin: 1rem auto;
    padding: 15.3642px 30.7283px;
    background: #00a7d9;
    box-shadow: 0px 34.8254px 71.6994px rgba(255, 81, 36, 0.1);
    border-radius: 15.3642px;
    font-style: normal;
    font-weight: 600;
    font-size: 28.6798px;
    color: #ffffff;
    outline: none;
    border: none;
    transition: 0.2s background;
  }

  button[type="submit"]:hover,
  input[type="submit"]:hover {
    background: #083f88;
  }

  button[type="submit"]:active,
  input[type="button"]:active,
  input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  button[type="submit"]:focus,
  input[type="button"]:focus,
  input[type="submit"]:focus {
    outline: none;
  }

  input[type="text"]:focus,
  input[type="number"]:focus,
  input[type="email"]:focus {
    outline: none;
    border: 1px solid dodgerblue;
  }
  textarea:focus {
    outline: none;
    border: 1px solid dodgerblue;
  }
  input[type="button"]:hover {
    transition: 0.3s all;
  }

  button[type="submit"],
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
  }
`;

const Forms = (props) => {
  return (
    <FormWrapper {...props}>
      <Form
        style={props.formStyle}
        className={props.formClass}
        onSubmit={props.handleSubmit(props.onSubmit)}
      >
        {props.children}
        <input
          type="submit"
          value={props.buttonvalue ? props.buttonvalue : "Submit"}
        />
      </Form>
    </FormWrapper>
  );
};
export default Forms;
