import { Container } from "react-bootstrap";
import styled from "styled-components";
import checkmark from "../../../../../assets/images/checkmark.svg";
import ModalHeader from "../ModalHeader";
import styles from "./index.module.css";
const LiWrapper = styled.div`
  margin: 0 0 0 2rem;
  & ul {
    list-style: none;
    list-style-position: outside;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  & li {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  & .pseudo-li {
    background-image: url(${checkmark});
    background-size: contain;
    display: flex;
    min-width: 10px;
    width: 10px;
    height: 12px;
    margin-top: 6px;
    margin-right: 5px;
    background-repeat: no-repeat;
  }
`;

const ChannelsModalContent = ({ closeModal }) => {
  return (
    <Container fluid className={styles.container}>
      <div onClick={closeModal} className={styles.close}>
        x
      </div>
      <div className={styles.padbox}>
        <ModalHeader title="Additional Channels Available" />

        <LiWrapper>
          <ul style={{ width: "100%" }}>
            <li>
              <div className="pseudo-li"></div>Banker's Plus
            </li>
            <li>
              <div className="pseudo-li"></div>Banker's Advantage (for bank
              employees)
            </li>
            <li>
              <div className="pseudo-li"></div>Wholesale
            </li>
            <li>
              <div className="pseudo-li"></div>Correspondent
            </li>
          </ul>

          <p style={{ padding: "1rem 0 0 1rem" }}>
            Inquiries are welcome for the above Channels.
            <br />
            <span
              style={{
                fontWeight: 600,
                display: "inline-block",
                marginTop: "1rem",
              }}
            >
              “Our Goal is to help Banks Prosper in Mortgage Lending”
            </span>
          </p>
        </LiWrapper>
      </div>
    </Container>
  );
};

export default ChannelsModalContent;
