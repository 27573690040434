import Modal from "../Modal";
import React, { useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import CustomCard from "../../CustomCard";
import styles from "./index.module.css";
import PartnerModalContent from "./modalContents/PartnerModalContent";
import HowToModalContent from "./modalContents/HowToModalContent";
import ChannelsModalContent from "./modalContents/ChannelsModalContent";
import partner from "../../../assets/images/AddOnSection/Partner.svg";
import howto from "../../../assets/images/AddOnSection/HowTo.svg";
import channels from "../../../assets/images/AddOnSection/Channels.svg";
import styled from "styled-components";
const AddOnWrapper = styled.div`
margin-top:0rem;
 
background: rgba(10, 10, 10, 0.03);
  & .addon_modal{
    position:fixed;
    top:0;
    left:0
    height:100%;
    width:100%;
    background:rgb(88,88,88,0.5);
    display:flex;
    justify-content:center;
    align-items:flex-start;
    overflow:auto;
    -ms-overflow-style: none;
    scrollbar-width: none;  
  }
  & .addon_modal::-webkit-scrollbar {
    display: none;
  }

  & .addon_modal_item{
    max-width:1000px;
    margin:5rem 5rem 3rem 5rem;
background:white;  
border-radius: 10px;
}
@media(max-width:991px){
    padding-top:5rem;
    
}
@media (max-width:600px){
    & .addon_modal_item{
        max-width:1000px;
        margin:5rem 1rem 0 1rem;
    background:white;  
    }
}
`;
const data = [
  {
    id: "PARTNER",
    img: partner,
    heading: "Partner with Sun West!",
    modalHeading: "Partner with Sun West!",
    description:
      "Sun West Mortgage Company, Inc. is a FHA, VA, Freddie Mac, Fannie Mae** and Ginnie Mae approved full-service mortgage banker. ",
    link: "/assets/images/AddOnSection/Partner.svg",
  },
  {
    id: "HOW_TO",
    img: howto,
    heading: `How to become a Sun West's "Banker's Plus" Partner`,
    modalHeading: `Sun West's "Banker's Plus" Partner`,
    description: "Easy as 1, 2 and 3!",
    link: "/assets/images/AddOnSection/HowTo.svg",
  },
  {
    id: "CHANNELS",
    img: channels,
    heading: "Additional Channels Available",
    modalHeading: "Additional Channels Available",
    description: `<ul><li>Banker's&nbsp;Plus</li><li>Banker's Advantage<br>(for bank employees)</li><li>Wholesale</li><li>Correspondent</li></ul>`,
    link: "/assets/images/AddOnSection/Channels.svg",
  },
];

const {
  addOn_container,
  addOn_cards_container,
  addOn_cards_col,
  addOn_card,
  addOn_card_middle,
  addOn_card_image,
  addOn_card_image_middle,
  addOn_card_body,
  addOn_card_title,
  addOn_card_sub_title,
  addOn_card_actionable,
  footerClassName,
  addOn_card_button,
} = styles;

const AddOnCards = () => {
  const [show, setShow] = useState(false);
  const [id, setId] = useState("");

  const openModal = (id) => {
    setShow(true);
    setId(id);
  };
  const closeModal = () => {
    setShow(false);
    setId("");
  };

  return (
    <AddOnWrapper>
      <div className={addOn_container}>
        {show && (
          <div className="addon_modal" style={{ zIndex: 10000 }}>
            <div
              onClick={() => setShow(false)}
              className="addon_modal"
              style={{ zIndex: 10001 }}
            ></div>
            <div className="addon_modal_item" style={{ zIndex: 10002 }}>
              {id === "PARTNER" && (
                <PartnerModalContent closeModal={closeModal} />
              )}
              {id === "HOW_TO" && <HowToModalContent closeModal={closeModal} />}
              {id === "CHANNELS" && (
                <ChannelsModalContent closeModal={closeModal} />
              )}
            </div>
          </div>
        )}
        <Container className={addOn_cards_container}>
          <Col className={addOn_cards_col} lg={4} md={12}>
            <CustomCard
              genericClassnameObj={{
                card: addOn_card,
                cardImage: addOn_card_image,
                cardBody: addOn_card_body,
                title: addOn_card_title,
                subTitle: addOn_card_sub_title,
                actionable: addOn_card_actionable,
              }}
              cardStyles={{
                padding: "0",
                height: "100%",
                footerAction: {
                  position: "absolute",
                  bottom: "1rem",
                },
              }}
              contentStyles={{
                textAlign: "left",
              }}
              content={{
                img: data[0].img,
                heading: data[0].heading,
                description: data[0].description,
                actionableItem: () => (
                  <button
                    className={addOn_card_button}
                    onClick={() => openModal(data[0].id)}
                  >
                    Read More
                  </button>
                ),
              }}
              onClickCard={() => openModal(data[0].id)}
            />
          </Col>
          <Col className={addOn_cards_col} lg={4} md={12}>
            <CustomCard
              genericClassnameObj={{
                card: addOn_card_middle,
                cardImage: addOn_card_image_middle,
                cardBody: addOn_card_body,
                title: addOn_card_title,
                subTitle: addOn_card_sub_title,
                actionable: addOn_card_actionable,
              }}
              cardStyles={{
                padding: "0",
                height: "100%",
                footerAction: {
                  position: "absolute",
                  bottom: "1rem",
                },
              }}
              contentStyles={{
                textAlign: "left",
              }}
              content={{
                img: data[1].img,
                heading: data[1].heading,
                description: data[1].description,
                actionableItem: () => (
                  <button
                    className={addOn_card_button}
                    onClick={() => openModal(data[1].id)}
                  >
                    Read More
                  </button>
                ),
              }}
              onClickCard={() => openModal(data[1].id)}
            />
          </Col>
          <Col className={addOn_cards_col} lg={4} md={12}>
            <CustomCard
              genericClassnameObj={{
                card: addOn_card,
                cardImage: addOn_card_image,
                cardBody: addOn_card_body,
                title: addOn_card_title,
                subTitle: addOn_card_sub_title,
                actionable: addOn_card_actionable,
              }}
              cardStyles={{
                padding: "0",
                height: "100%",
                footerAction: {
                  position: "absolute",
                  bottom: "1rem",
                },
              }}
              contentStyles={{
                textAlign: "left",
              }}
              content={{
                img: data[2].img,
                heading: data[2].heading,
                description: data[2].description,
                actionableItem: () => (
                  <button
                    className={addOn_card_button}
                    onClick={() => openModal(data[2].id)}
                  >
                    Read More
                  </button>
                ),
              }}
              onClickCard={() => openModal(data[2].id)}
            />
          </Col>
        </Container>
      </div>
    </AddOnWrapper>
  );
};

export default AddOnCards;
