import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import styles from "./index.module.css";
import left from "../../../assets/images/BenefitsSection/benefits-left.png";
// import image from '../../../assets'

const data = [
  {
    img: "../../../assets/images/BenefitsSection/CustService.svg",
    title: "Provide an outstanding level of Customer Service/Communication",
  },
  {
    img: "../../../assets/images/BenefitsSection/AmplePrograms.svg",
    title:
      "Offer Extensive and Diverse Loan programs to the Bank’s customer base & community",
  },
  {
    img: "../../../assets/images/BenefitsSection/CustBase.svg",
    title: "Earn valuable Fee Income",
  },
  {
    img: "../../../assets/images/BenefitsSection/ServiceIncome.svg",
    title: "Reduce Overhead",
  },
  {
    img: "../../../assets/images/BenefitsSection/RiskExposure.svg",
    title: "Minimize Risk & Exposure",
  },
  {
    img: "../../../assets/images/BenefitsSection/Growth.svg",
    title: "Depository Base Retention & Growth",
  },
];

const {
  benefits_section_container,
  benefits_section_info_container,
  benefits_section_row,
  benefits_section_title,
  benefits_section_subtitle,
  benefits_section_content,
  benefits_section_item,
  benefits_subheader,
} = styles;

const Benefits = () => {
  return (
    <Row className={benefits_section_container}>
      <Col
        className="d-flex justify-content-center align-items-center m-0 p-0"
        lg={5}
      >
        <Image src={left} fluid />
      </Col>
      <Col className={benefits_section_info_container}>
        <Row className="mx-0">
          <Col lg={12} className={benefits_section_row}>
            <Row className={benefits_section_title}>
              <p className={benefits_subheader}>
                STAY ACTIVE & VIABLE IN MORTGAGE LENDING
              </p>
            </Row>
            <Row className={benefits_section_title}>
              <b style={{ color: "#00AAE4" }}>Banker's Plus Benefits</b>
            </Row>
            <Row className={benefits_section_subtitle}>
              <div>
                Sun West’s “Banker’s Plus” program has the following bank
                benefits:
              </div>
            </Row>
            <Row className={`${benefits_section_content} px-0`}>
              {data.map((benefit) => (
                <Col className="mx-0" lg={6} md={9} sm={12} xs={12}>
                  <div className={benefits_section_item}>
                    <img
                      style={{
                        width: "90%",
                      }}
                      src={benefit.img}
                    />

                    {benefit.title}
                  </div>
                </Col>
              ))}
            </Row>

            <Row className={benefits_section_title}>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  marginBottom: "8px",
                }}
              >
                It’s as easy as – Your Bank Originates & We Do the Rest!
              </p>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Benefits;
