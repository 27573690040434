/*
 * Footer used across all the pages
 */

import React, { Suspense } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { lazy } from '@loadable/component';
import styles from "./footerNew.module.css";
import Subscriber from "../MailSubscriber";
// const  = lazy(() => import('../../'));
import eho from "../../assets/images/EHO-logo.png";
import person from "../../assets/images/footer/person-icon.svg";
import dot from "../../assets/images/footer/dot-icon.svg";
import phone from "../../assets/images/footer/phone-icon.svg";
import envelope from "../../assets/images/footer/envelope-icon.svg";
const FooterWrapper = styled.div`

@media (min-width:1380px){
  .container{
  
padding: 0 50px;
  }
  .awardsSecondCol.col-md-5.col-sm-12.col-12 {
    flex: 28%;
    max-width: 28%;
}
`;

const footer = (props) => {
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <FooterWrapper>
      <div>
        <footer>
          <Container className="px-0">
            <Row style={{ marginTop: "20px" }} className="mx-0">
              <Col lg={7} md={6} sm={12} xs={12} className={styles.desctypo}>
                <div className={styles.logosize}>
                  {/* <Suspense fallback={<div>Loading...</div>}>
                    <>
                      <img
                        src="/swmc-images/Footer/logo_smls_header.png"
                        alt="SunWest Logo"
                        className={styles.footer_logo}
                      />
                    </>
                  </Suspense> */}
                  <Suspense fallback={<div>Loading...</div>}>
                    <>
                      <img
                        src={eho}
                        alt="SunWest Logo"
                        className={`${styles.homicon} ` + `img-fluid`}
                      />
                    </>
                  </Suspense>
                </div>
                <br />
                <div className={styles.footer_desc_text}>
                  <p style={{ lineHeight: "25px" }}>
                    {" "}
                    For licensing information, go to:{" "}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          NMLS Consumer Access
                        </Tooltip>
                      }
                    >
                      <a
                        href="http://www.nmlsconsumeraccess.org/EntityDetails.aspx/company/3277"
                        target="_blank"
                        rel="noopener"
                      >
                        www.nmlsconsumeraccess.org
                      </a>
                    </OverlayTrigger>
                    . <br />
                    Visit{" "}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Click here for full list of license information of Sun
                          West Mortgage Company, Inc
                        </Tooltip>
                      }
                    >
                      {/* <Link to="/disclaimer">Click Here</Link> */}
                      <a
                        href="https://www.swmc.com/disclaimer"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.swmc.com/disclaimer
                      </a>
                    </OverlayTrigger>{" "}
                    for the full list of license information. <br />
                  </p>
                  <p>
                    Being High Risk Loans, Non-Conforming Jumbo & Non-QM loans
                    may include higher interest rates, closing costs,
                    Interest-Only periods or Pre-payment penalties.
                  </p>
                  <p className="mb-0 pt-3">
                    Although Sun West Mortgage is approved to conduct business
                    in the state of New York, this website has not yet been
                    approved by the State of New York Department of Financial
                    Services. For properties located in the State of New York,
                    this website can not be used to upload an application,
                    please visit{" "}
                    <a href="https://www.swmc.com/" target="_blank">
                      SWMC.com
                    </a>{" "}
                    to upload an application.
                  </p>
                  {/* {url &&
                  <p>
                    Although Sun West Mortgage Company is approved to conduct business in the state of New York, this website has not
                    yet been approved by the State of New York Department of Financial Services. For properties located in the State of New York, this website can not be used to upload an application, please visit <a href="https://swmc.com" target="_blank" rel="noopener">SWMC.com</a> to upload an application.
                  </p>
                  } */}
                </div>
              </Col>
              <Col lg={5} md={6} sm={12} xs={12} className={styles.desctypo}>
                <h6>SUBSCRIBE TO OUR NEWSLETTER</h6>
                <p style={{ marginBottom: "0px" }}>
                  Stay in the loop by subscribing to our newsletter.
                </p>
                <p style={{ marginBottom: "10px" }}>
                  Get breaking news or the latest on loan rates.
                </p>
                <Subscriber />
                <div className={styles.contact_block}>
                  <h4>Contact Us</h4>
                  <div className={styles.ul_footer}>
                    <div style={{ display: "flex" }}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <>
                          <img
                            style={{
                              width: "15px",
                              height: "15px",
                              marginTop: "4px",
                            }}
                            alt="logo-image"
                            src={person}
                          />
                        </>
                      </Suspense>
                      <p>
                        <a
                          style={{ color: "#fff" }}
                          target="_blank"
                          href="http://maps.google.com/?q=6131 Orangethrope Ave Suite 500 Buena Park, CA 90620"
                          rel="noopener"
                        >
                          Sun West Mortgage Company, Inc. NMLS 3277
                        </a>
                      </p>
                    </div>

                    <div style={{ display: "flex" }}>
                      <Suspense fallback={<div>Loading...</div>}>
                        <>
                          <img
                            style={{
                              width: "15px",
                              height: "15px",
                              marginTop: "4px",
                            }}
                            alt="logo-image"
                            src={dot}
                          />
                        </>
                      </Suspense>
                      <p>
                        <a
                          style={{ color: "#fff" }}
                          target="_blank"
                          href="http://maps.google.com/?q=6131 Orangethrope Ave Suite 500 Buena Park, CA 90620"
                          rel="noopener"
                        >
                          6131 Orangethorpe Avenue,
                          <br /> Suite 500 Buena Park, California 90620
                        </a>
                      </p>
                    </div>
                    <div className={styles.contactOptions}>
                      <div style={{ display: "flex" }}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <>
                            <img
                              style={{
                                width: "15px",
                                height: "15px",
                                marginTop: "4px",
                              }}
                              alt="logo-image"
                              src={phone}
                            />
                          </>
                        </Suspense>
                        <p>
                          <a href="tel:+18004537884" style={{ color: "#fff" }}>
                            (800) 453-7884
                          </a>
                        </p>
                      </div>
                      <div className={styles.email}>
                        <Suspense fallback={<div>Loading...</div>}>
                          <>
                            <img
                              style={{
                                width: "15px",
                                height: "15px",
                                marginTop: "4px",
                              }}
                              alt="logo-image"
                              src={envelope}
                            />
                          </>
                        </Suspense>
                        <p>
                          <a
                            href="mailto:inquiry@swmc.com"
                            style={{ color: "#fff" }}
                          >
                            inquiry@swmc.com
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className={styles.social_footer}>
                    <a
                      href="https://www.facebook.com/SunWestMortgage"
                      target="_blank"
                      rel="noopener"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "10px",
                            }}
                            alt="logo-image"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAAEA0lEQVRogd2azWtcVRjGf881qG1taYugKIrxo+lCElFp09hFVBCFFF0ouLD9IxRBVBQXpeB30S7EjQi60ZUoglZtxLa2kbZxoVRpa1SwqE2NNv0yyePiztTJ5N6byb3nzFR/MMxkzjnv+z73Ts5533dGBMT2amAQ6ANWAdcAK4BLalNOAOPAGHAQGAV2SDoYMo5K2L7J9ou2f3Z5frL9gu2+TomQ7Q22d1cQkccu20O21S4xa2yPRBDSzB7bt8YUssj2K7an2yCmzrTtrbYvDi2mx/ZoG4U0s9/2DaHErLX9ewfF1Bm3vb6qmHtsn+ywkEYmbd9dFHPuTmK7H9gOLKl0VcJzCrhL0hdZg5mCbPcAXwLLIwZWhXGgX9L3zQNzBNleRCqmN2JAv5BmC8eB08BS4MLa8+XAZUAyj439wICk04WzbL8a6fN/1PZjbmG3sv1GizZfns/QGsc5Z950eudbYgGCpmzf3Lg2aTAiYBvz3+qFslXSJkmnAtsFuADY5oY0qTH4ISB0qrEHeDSwzWb6gXNbeaOgxyM4e1rS3xHsNvNk/UUXpCUAqdKQfAd81MpE28uZveNetEBfA7Z7JX1dv0ObFmigFbZLct6g7cW2X7c9Sbp9jzc8Hizhb2Oj8SrFWR4bC5xj+7XA/sYAEqdl85Ulrsh8HCkQswx4KLC/q21fn5D2AGIwUTA2CCyO4POOhLShEYM/C8auiOSzLwF6IhmfKRhbEclnTwJ0RzJeROhspE53AiyLZLwTLEv4twn4f2CpbJ8hrUXK8D75h/KEpMz/I6ddnKLs+0NgbYl4znYBJykv6Kyk4wtdVCvKcgsz29eVjOevBPij5OIo2F4JXFpy+URCwYneIVZXWHsoAeY0GjpMlXPxUAJ8FSqSQFS5QyNdwM4KBpbYvjZn7EdJU1kDTuuflTnrqqRiO+tfjYwFTuVt+6o8r7afiODviG0ltSLs3QpX5XzhHUmu51RvdTSUMLwNtSRR0j4gs1f8H2GHpAMwO+t9rkPBhOD5+otzgiS9R7Udr1N8LumD+h/NdcnDwHR746nENPBI4xuzBEnaC7zUzogq8qykWYlBVuX4FHCgPfFUYh/wTPObcwTVmur3Ab+1IaiyHAPul3SmeSCztpc0RipqMnJgZTgBbJCUWSXkNisk7QLuJS0AzxcmgSFJu/MmFHZfJH1C2hT8NXBgZTgKDEoaLpo0bztJ0giwjs6WGXuBdc07WhYt9cckHQZuI80mMkuCSEwBW4D1kn6I4sF2r+3hNpQPn9m+MYqInKDutP2p7ZmAgmZsf2z79rYJyQhule0ttr+pIOhb25sd4AdKQX9kZ7sbGABuATZLOpYz7wHSr0BHgeHauReEfwBdOEQgVhqkhAAAAABJRU5ErkJggg=="
                          />
                        </>
                      </Suspense>
                    </a>

                    <a
                      href="https://www.twitter.com/SunWestMortgage"
                      target="_blank"
                      rel="noopener"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "10px",
                            }}
                            alt="logo-image"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAAD7klEQVRoge2aW4hVVRyHv/80MgWWmE5MkhCmUsZgBCpJoVlZWWgv0UugVOSDET4EQUEXiuglsvKlCySUBdqDL/ZQVHS16CZKilggxVBmajVMONPMfD3sM8PxzDl7zmXtGYjzwYE5a63zX7/fWmvvdRto06ZNm/8zMd0C1EXABqAX6AFGgN+A/cCeiPh5GuXVj7pc/dB8RtW96hU5cS5Wt6lzq2XOVzcXbCTUx0ti6+VfdWtZjHPUG9Q31EF1Z63KnlNH1DsLNPRaA0Yq2am+o54oS/tTvaRWy/WVCp1R1xVg5qEWzFRj2FqNry6oKDykbkxo5tJSQ6ViSN1Uit2tblFXdZTVuaBCwwzgdfUxtYPWeQLoShAHYBB4GehVPwb6gFuAT8ZLqHfktMYH6rxma1fPVfsT9k4l36nnA5S3/N85mtYA+9WNajNz1ypgZhO/q4cDwK0R0Q9nGzoyyQ+7gR3APnVFg5Ve1mD5evkIWB0Rx8cSxg1FRB/wYx1BVgBflobhujp7rKdhqZOzG1gbEafLEysf9jcbCLgG2AscUZ9Wr8op+1cDcevlYEQMVyZWGtoOnK4sNAmLgEeB79Vj6lvqVvU6s5XHDOCX5jTnMsFMVdRNid9Ao2azeWoeqKa/o8xIF0BE7AC2pWjCEgHMShhvjKrDuHzI3aV+rt4NPAM8BViAkFQcq5bYWfb378DK0mfs+xDpZvfU/FQtcfyVq3aTbaxSLHOK5hQwNyImjKDyeegE8M1UqmqBL6qZgYm98eIUiEnBZ7Uyzprl1U7gK+DqohW1yNKIOFAtY8KyRV1CZqqoxWSrHI2IxbUyJ7wAIuIQcBswUKSqFtiVl1lzYWl20vI2sDS1ohYYARZGRNU5CHJe0RFxGFgG3Af8kF5bU+zJMwP5PbQBmA/0A7PJDgNXJ5XXGALLIuLbvEKdeZnAS+n0tMzuycxAfg91APuA5SlVNckZoDciJt2A5j1Do8D9wD8JhTXLk/WYqQuz06DBAvYz9fJ1acJPh3qjenwazJxUK88Lk5mao25XB6bIzLC6tlGdDZ+xqRcCN5PtmxYDNzUTZ7JqgM0R8WriuDk16jXqoQJ6ZlTdMpVGutXnza5dUjOo3jNVRhaqL1jcM3RSvb5VnXkT6wVk+6JrgdvJ1nVFbc/fB+6NiJbP70KdCTwIrC+lnQfMAybeWabnFPAI8EqtLXXTqEvUXQUNp0oG1GfV2UlN1DC20uwuM+Vt2xhH1YfVi4rSn/cMzSLbMqwn2zbMaSK+ZP9v8B7wLvBp8qFVQV0TotnK+3Ky3euVZPukHrJzhy6y1XB/6fMrcJjsvulgRPyRXnabNm2K4j90Lz45GkRkcwAAAABJRU5ErkJggg=="
                          />
                        </>
                      </Suspense>
                    </a>
                    <a
                      href="https://www.linkedin.com/company/sun-west-mortgage-company/about/"
                      target="_blank"
                      rel="noopener"
                    >
                      <Suspense fallback={<div>Loading...</div>}>
                        <>
                          <img
                            style={{
                              height: "30px",
                              width: "30px",
                              margin: "10px",
                            }}
                            alt="logo-image"
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAABmJLR0QA/wD/AP+gvaeTAAACdElEQVRoge2Zz4tNYRjHP89NjGFGGKEs/MhisDMUhULKYiz8KJmVnR1ZkJ2tGv4AmRoLiylJspDsJI2EQvlRYyHJSGqSdOV+Le490+vOvee+rznd98ztfFbPec/zvD2fzj3vfc85UFDQVqx+QNJ84CgwCPQD3cAkMA6MmtnLtnY4GyT1S3qt5lQkXZFUit1rSyStkPQ5RcZlOHa/LZE07CkjSX8krY/dcyPcn86BwLrBjHvJBFeoN7C2L8tGssIVehtY+ybLRjJH0pGAe+iLpJ7YPbdE0oiHTFnSwdi9eiGpJOm0pMkmMs8k7YjdZxozdgoAkrqAXcBmYDHwFRg3sxdt7K0AnCskaQuw07OuDNw0sx+1WgMOA8s96yfM7EH9oKQ1wFpgJbAU+A58AF6ZWdlz7unJJgJWOUm66NTuD6yt1JpH0jpJl5S+h5ySdEPS1lYe7v9Q6DLc2yT2wYANkq4D74FzwKaU/B7gBPBE0mVJC5slzgtsJEvuAV2BNSXgLLBb0j4zm2qUEItQGZcB4I6qq/E/5P+5pjl7gAv1g3NZCOC8pI3uwFwXWgCccQfmuhDAkKTu5KAThJYAe5ODThAC2J4EMYUEjALbqN4LfcBx/u/BcSAJYgqdMrOTZvbUzMpm9s3MxqgKPgyca1USxBK6ZWZXG52obXiHgF8B8y1LglhCI2knzewjcD9gvkVJEEvonUdO6EsbIJ7Qb4+csOefGp2ybE9TCOWdQijvFEJ5pxDKO4VQ3imE8k5HCymwVk3i0NrZ5MzIdV/WXwOO4XfVfgK3nePHwCNgtUftc+CTR95d4BDVL4hpVIAxj/kKCgo8+AuAHwTPGUGp9QAAAABJRU5ErkJggg=="
                          />
                        </>
                      </Suspense>
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container  className={styles.footer_container}>
            <Row className="mx-0 px-0 w-100 d-flex flex-column flex-lg-row" >
              <Col xl={5} lg={12} className="order-2 order-xl-1 my-2 p-0">
                <p
                  className={`${styles.footer_desc_text} text-center`}
                  style={{ fontSize: "13px",margin:0,padding:0}}
                >
                  © {currentYear} Sun West Mortgage Company, Inc. All Rights
                  Reserved
                </p>
              </Col>
              <Col xl={7} lg={12}  className="my-2 pr-0s pl-0s order-1 order-xl-2">

                <ul className={`${styles.footer_list} my-1`}>
                  <li>
                    <a
                      href="https://www.swmc.com/contact-us"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.swmc.com/borrower-concerns-complaints"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Borrower Concerns & Complaints
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.swmc.com/privacy-policy.php"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.swmc.com/disclaimer"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Licenses
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.swmc.com/swmc-site-map"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Site Map
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </FooterWrapper>
  );
};

export default footer;
