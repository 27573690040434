import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Card, Container, Col, Button, Row } from "react-bootstrap";
import CustomCard from "../../components/CustomCard";
import InnerBanner from "../../components/InnerBanner";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const data = {
  title: "Sun West Mortgage Solutions",
  subTitle:
    "Here is Sun West Mortgagae Solutions Lorem ipsum content. We run multiple services and much more content to show, lets focus on content",
  cards: [
    {
      img: "../../assets/images/SolutionsSection/BankerPlus.svg",
      heading: "Banker's Plus",
      description:
        "Lorem ipsum is simply dummy text for printing and HTML work",
      link: "/images/SolutionsSection/BankerPlus.svg",
    },
    {
      img: "../../assets/images/SolutionsSection/BankerAdvantage.svg",
      heading: "Banker's Advantage",
      description:
        "Lorem ipsum is simply dummy text for printing and HTML work",
      link: "/images/SolutionsSection/BankerAdvantage.svg",
    },
    {
      img: "../../assets/images/SolutionsSection/Wholesale.svg",
      heading: "Wholesale",
      description:
        "Lorem ipsum is simply dummy text for printing and HTML work",
      link: "/images/SolutionsSection/Wholesale.svg",
    },

    {
      img: "../../assets/images/SolutionsSection/Wholesale.svg",
      heading: "Correspondent",
      description:
        "Lorem ipsum is simply dummy text for printing and HTML work",
      link: "/images/SolutionsSection/Wholesale.svg",
    },
  ],
};

const {
  solutions_section_container,
  title,
  sub_title,
  solutions_cards_container,
  solutions_card_action_btn,
  solutions_card,
  solutions_card_image,
  solutions_card_body,
  solutions_card_title,
  solutions_card_sub_title,
  solutions_card_actionable,
} = styles;

const SolutionsSection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <article>
      <Helmet>
        <title>Sun West Mortgage Solutions</title>
        <meta
          property="og:url"
          content="https://bankers.plus/sun-west-mortgage-solutions"
        />
        <meta
          property="og:title"
          content="Banker's Plus - Sun West Mortgage Solutions"
        />
        <meta
          property="og:description"
          content="Residential mortgage loan and financing are provided by Banker's Plus"
        />
        <meta
          property="og:keyword"
          content="Bank Originates & Processes, SWMC Underwrites, SWMC, sun west mortgage"
        />
        <meta property="og:brand" content="Banker's Plus" />
      </Helmet>

      <Container className=" px-3 p-md-0 mt-5 ">
        <Row className="m-0 mt-5 pt-3">
          <Col className="mt-5 pb-3">
            <h1>Sun West Mortgage Solutions</h1>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col lg={5} md={12} className="p-0">
            <div className={styles.title_col}>
              <div className={styles.solution_header_left}>
                <span className={styles.img_span}>
                  <img
                    className={styles.solution_card_icon}
                    src="../../assets/images/SolutionsSection/BankerPlus.svg"
                  />
                </span>
              </div>
              <div className={styles.solution_header_right}>
                <h3>Banker's Plus</h3>
              </div>
            </div>
            <ul className={styles.subtext}>
              <li>
                Provide an outstanding level of Customer Service/Communication
              </li>
              <li>
                Offer Extensive and Diverse Loan programs to the Bank’s customer
                base &amp; community
              </li>
              <li>Earn valuable Fee Income</li>
              <li>Reduce Overhead</li>
              <li>Minimize Risk &amp; Exposure</li>
              <li>Depository Base Retention &amp; Growth</li>
            </ul>
          </Col>
          <Col lg={7} md={12} className="p-0">
            <div className={styles.title_col}>
              <div className={styles.solution_header_left}>
                <span className={styles.img_span}>
                  <img
                    className={styles.solution_card_icon}
                    src="../../assets/images/SolutionsSection/BankerAdvantage.svg"
                  />
                </span>
              </div>
              <div className={styles.solution_header_right}>
                <h3>Banker's Advantage</h3>
              </div>
            </div>
            <p className={styles.subtext}>
              Loans for Bank Employees, Bank Officers, Bank Directors &amp; Bank
              Shareholders. Sun&nbsp;West offers a special mortgage program to
              bank employees for their personal home loans under the "Banker's
              Advantage" program. This is a great benefit to your bank
              employees, officers, directors and shareholders as they can save a
              substantial amount of money on their next home loan. The benefits
              are no lender fees, special rates and no cross-selling. Plus, many
              bank employees do not like to share their personal financial
              information with other employees at their own bank so they go down
              the street to get their loan; going through this program your
              information is not shared with any bank staff. For Officers,
              Directors and Shareholders Regulation ‘O’ is avoided.
            </p>
          </Col>
          <Col lg={5} md={12} className="p-0">
            <div className={styles.title_col}>
              <div className={styles.solution_header_left}>
                <span className={styles.img_span}>
                  <img
                    className={styles.solution_card_icon}
                    src="../../assets/images/SolutionsSection/Wholesale.svg"
                  />
                </span>
              </div>
              <div className={styles.solution_header_right}>
                <h3>Wholesale</h3>
              </div>
            </div>
            <p className={styles.subtext}>
              Bank Originates &amp; Processes, Sun West Underwrites and draws
              Docs in the name of Sun West &amp; Funds.
            </p>
          </Col>
          <Col lg={7} md={12} className="p-0">
            <div className={styles.title_col}>
              <div className={styles.solution_header_left}>
                <span className={styles.img_span}>
                  <img
                    className={styles.solution_card_icon}
                    src="../../assets/images/SolutionsSection/Wholesale.svg"
                  />
                </span>
              </div>
              <div className={styles.solution_header_right}>
                <h3>Correspondent</h3>
              </div>
            </div>
            <p className={`${styles.subtext} ${styles.fontsize}`}>
              Bank Originates &amp; Processes, Sun West Underwrites, Client
              draws Docs &amp; Funds.
              <br />
              Bank Originates &amp; Processes, Sun West Underwrites &amp; draw
              Docs, Bank Funds.
            </p>
          </Col>
        </Row>
        <Row className="my-5">
          <Col>
            <p className={styles.subtext}>
              <Link to="/contact-us" title="Contact Us">
                Contact us
              </Link>{" "}
              for further discussion on our Mortgage Solutions to review how
              Sun&nbsp;West can help your bank stay active and viable in
              residential mortgage lending.
            </p>
          </Col>
        </Row>
      </Container>
    </article>
  );
};

export default SolutionsSection;
