import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import { withRouter } from 'react-router-dom'
import Classes from './index.module.css';


const ErrorFallback = props => {


    useEffect(() => {
        console.log("fallback component")
      }, [])


    function Fallback({ error, resetErrorBoundary }) {
        const handleReset = () => {
            resetErrorBoundary()
        }

        const handleClick = (e) => {
            resetErrorBoundary()
        }
        return (

            <div role="alert">
                <Helmet titleTemplate="Banker's Plus" defaultTitle="SWMC" />
                <Container fluid className={Classes.pageContainer}>
                    <div
                        className={Classes.hero_image}
                        style={{ color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                        {/* <h1>OH NO !!!</h1> */}
                        <section className={Classes.pageSection}>
                            <h1>Oh no! Something went wrong.</h1>
                            <p>

                                Sorry for the inconvenience, We are working to fix this issue
                                for you.
                            </p>

                            <p>If you are seeing this error, please write us at <a href="mailto:inquiry@swmc.com">inquiry@swmc.com</a> and let us know the page you were trying to access when the error occurred. We apologize for the inconvenience and thank you for your interest in Sun West Mortgage Company, Inc.</p>

                            {/* <p style={{ display: 'none' }}> You have encountered the following error : <pre style={{ color: 'black' }}>{error.message}</pre></p> */}

                        </section>
                    </div>
                  
                </Container>
            </div>
        )
    }

    return (
        <ErrorBoundary
            FallbackComponent={Fallback}
            onReset={() => {
                // reset the state of your app so the error doesn't happen again
            }}
        >
            
            {props.children}
        </ErrorBoundary>
    )


};

export default ErrorFallback;
